import React from 'react'
import PropTypes from 'prop-types'
import contentfulUrlForLink from 'lib/contentful-url-for-link'
import { linkPropType, urlLocationPropType } from 'lib/prop-types'
import Link from 'components/Link'

class PageNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.activeRef = React.createRef()
    this.navRef = React.createRef()
  }

  componentDidMount() {
    // scroll to active nav item (on mobile)
    if (this.activeRef.current && this.navRef.current) {
      const active = this.activeRef.current.parentNode
      const nav = this.navRef.current
      nav.scrollLeft = active.offsetLeft
    }
  }

  render() {
    const { location } = this.props
    const currentPage = location.pathname.replace(/$\/|^\//, '') // remove leading and trailing slashes

    return (
      <div className="section section--padded-small bb--grey-light">
        <div className="section__inner">
          <div className="nav__secondary" ref={this.navRef}>
            {this.props.links.map(link => {
              const url = contentfulUrlForLink(link)
              const hasActiveChild =
                currentPage.startsWith(url) && currentPage[url.length] === '/'
              return (
                <Link
                  key={`mainNavigation${url}`}
                  to={url}
                  className={`nav__secondary--item b-r--3 ${
                    hasActiveChild ? 'active' : ''
                  }`}
                  activeClassName="active"
                >
                  <span
                    ref={
                      hasActiveChild || url === currentPage
                        ? this.activeRef
                        : null
                    }
                  >
                    {link.label}
                  </span>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

PageNavigation.propTypes = {
  links: PropTypes.arrayOf(linkPropType),
  location: urlLocationPropType,
}

export default PageNavigation
