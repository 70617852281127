// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeArticle from 'components/Contentful/KnowledgeArticle'
import SideNavigation from 'components/SideNavigation'
import PageNavigation from 'components/PageNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Häufig gestellte Fragen - FAQs"
        meta={[
          { name: 'description', content: 'Sie haben Fragen rund um das Thema Wärmepumen? Finden Sie hier alle Informationen!' },
          { name: 'keywords', content: 'Fragen Wärmepumpe, FAQ Wärmepumpen' },
        ]}
      >
        
      </Helmet>
      
      <PageNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    {
        "type": "link",
        "id": "1xligmPvFk1S9Z6xdQzjAE",
        "updatedAt": "2020-03-27T13:38:18.329Z",
        "label": "Die Wärmepumpe einfach erklärt",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert"
        }
    }
]} /><div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "3YsoMo1NVNMD9R5EUCcPZY",
        "updatedAt": "2020-03-27T12:09:36.532Z",
        "label": "Eine Wärmepumpe ist",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/eine-waermepumpe-ist"
        }
    },
    {
        "type": "link",
        "id": "4eeVTOLecGuLIeZOm4LSQ",
        "updatedAt": "2020-03-27T14:19:22.162Z",
        "label": "Funktionsweise Wärmepumpe",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/funktionsweise-einer-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "mlApPFnIU8bx7k8iiIeJs",
        "updatedAt": "2020-03-27T14:53:37.132Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/haeufig-gestellte-fragen-faq"
        }
    }
]} /><div
          className='bg__flex--white'
          key='nVMWVor4kRLS1Y4djIe7Y-5lKic8weGIYG27CzJ9YQpb'
          id=''
        >
          <KnowledgeArticle
            {...{
    "type": "knowledgeArticle",
    "id": "5lKic8weGIYG27CzJ9YQpb",
    "updatedAt": "2023-04-20T16:10:04.097Z",
    "title": "Häufig gestellte Fragen -  FAQ",
    "keyword": "Häufig gestellte Fragen - ",
    "articleType": "FAQ",
    "description": "Alles, was Sie schon immer über das Thema Wärmepume wissen wollten und mit wenigen Worten beantwortet werden kann!",
    "content": [
        {
            "type": "FAQ",
            "id": "1NYzxpW6XdF2Uu6G7XLnz8",
            "updatedAt": "2020-03-27T14:31:41.043Z",
            "title": "Lautstärke Wärmepumpe",
            "question": "<p>Wie laut sind Wärmepumpen?</p>\n",
            "answer": "<p>Wärmepumpen haben unterschiedliche Geräuschpegel. Vergleichbar ist am besten die Schallleistung nach EN12102. Dieser Wert wird auch für die Beurteilung nach den national gültigen Lärmschutzvorschriften verwendet.</p>\n"
        },
        {
            "type": "FAQ",
            "id": "5uHOsB0cc8SjEmocBl2exb",
            "updatedAt": "2020-03-27T14:32:43.556Z",
            "title": "Wärme im Winter",
            "question": "<p>Warm im strengen Winter?</p>\n",
            "answer": "<p>Luft-Wasser Wärmepumpen arbeiten bis zu einer Aussentemperatur von -20 °C. Bei extremen und lang anhaltenden Minustemperaturen, sprich in einem Klima wo wochenlang, Tag und Nacht Temperaturen unter -10° C herrschen, sinkt die Effizienz ab – aber sie haben immer noch warm. Dafür sorgt in diesen Ausnahmefällen der elektrische Heizeinsatz - übrigens auch im Notbetrieb.</p>\n"
        },
        {
            "type": "FAQ",
            "id": "2bXRwyTiX8uq2xFgMqOnIr",
            "updatedAt": "2020-03-27T14:33:19.340Z",
            "title": "Wärmepumpen in Altbauten",
            "question": "<p>Machen Wärmepumpen in Altbauten Sinn?</p>\n",
            "answer": "<p>Tatsächlich werden in der Schweiz in rund 9 von 10 Neubauten Wärmepumpen eingebaut! – Auch für den Heizungsersatz macht das System Sinn. Und zwar besonders dann, wenn es sich um ein Haus mit verbesserter Wärmedämmung handelt oder der bisherige Verbrauch in einem normalen Rahmen liegt.</p>\n"
        },
        {
            "type": "FAQ",
            "id": "3caTUEOhsU7ibgyYdZA5o7",
            "updatedAt": "2020-03-27T14:34:28.690Z",
            "title": "Betriebskosten Wärmepumpe",
            "question": "<p>Wie hoch sind die Betriebskosten einer Wärmepumpe?</p>\n",
            "answer": "<p>Dank ihres genialen Prinzips braucht die Wärmepumpe nur ca. 1/3 der Energie, die beispielsweise eine Ölheizung benötigt. Der durchschnittliche Stromverbrauch für ein Einfamilienhaus mit ca. 120 m2 Wohnfläche beträgt statt 2’200 Liter Erdöl pro Jahr nur rund 6’100 kWh. Bei Stromkosten von durchschnittlich 20 Rp./kWh entspricht dies gut 1’220 Franken pro Jahr.</p>\n"
        },
        {
            "type": "FAQ",
            "id": "5ak95ddFLDzLFCT1n9Qt8v",
            "updatedAt": "2020-04-15T13:16:33.587Z",
            "title": "Heizkörper bei Wärmepumpen",
            "question": "<p>Ich heize mit Heizkörper. Kann ich trotzdem mit einer Wärmepumpe heizen?</p>\n",
            "answer": "<p>Ja, können Sie! Die heutigen Inverter-Wärmepumpen erreichen genügend hohe Temperaturen um Heizkörper mit ausreichend Wärme zu versorgen.\nLange Zeit war dies aber nicht möglich. Heizkörper erfordern eine Heizleistung von bis zu 50°C. Standard-Wärmepumpen konnten lange Zeit nur knapp diese Temperatur erreichen und stellten daher ein Risiko dar. Mittlerweile existieren Inverter-Wärmepumpen, welche Temperaturen von über 60°C erreichen und somit die Heizkörper mit ausreichend Wärme versorgen können.</p>\n"
        }
    ],
    "topic": {
        "type": "link",
        "id": "1xligmPvFk1S9Z6xdQzjAE",
        "updatedAt": "2020-03-27T13:38:18.329Z",
        "label": "Die Wärmepumpe einfach erklärt",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert"
        }
    },
    "previewImage": {
        "description": "Heizungsmacher - häufig gestellte Fragen",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/35SWWHYijCZ09BkUucaY7N/a83c972b34abd14bdf280e92dd70f1d8/faq-placeholder-img.png"
    },
    "previousArticle": "/Die-Waermepumpe-einfach-erklaert/funktionsweise-einer-waermepumpe",
    "nextArticle": "/Die-Waermepumpe-einfach-erklaert/eine-waermepumpe-ist"
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
